import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import { RichText } from "prismic-reactjs"
import Fade from "react-reveal/Fade"
import Breadcrumbs from "src/utils/Breadcrumbs"
import ScrollLock from "react-scrolllock"
import GatsbyLink from "src/utils/GatsbyLink"

const ArchiveMovieLibrary = props => {
  const VideosListing = props => {
    return (
      <Fade>
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-6">
          {props.data.map((item, index) => {
            return (
              <div
                key={index}
                className="group col-span-2 cursor-pointer text-left border-l border-gold mb-12 pointer-cursor outline-none focus:outline-none"
                role="button"
                tabIndex={0}
                onClick={() =>
                  handleVideoClick(
                    item.node.data.link_to_video.text,
                    item.node.data.youtube_link.richText
                  )
                }
                onKeyDown={() =>
                  handleVideoClick(
                    item.node.data.link_to_video.text,
                    item.node.data.youtube_link.richText
                  )
                }
              >
                <Fade>
                  <div className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70 relative">
                    {/* Disabling because it doesnt work on Safari */}
                    <img
                      src={item.node.data.featured_image.url}
                      alt={item.node.data.title.text}
                    />
                    <div className="video-icon absolute z-10">
                      <img src={"/video.svg"} alt="Play video" />
                    </div>
                  </div>
                  <div className="p-6 pb-0">
                    <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-6 mt-2">
                      {item.node.data.title.text}
                    </h2>
                    <div className="description">
                      <RichText
                        render={item.node.data.description.richText}
                        serializeHyperlink={GatsbyLink}
                      />
                    </div>
                  </div>
                </Fade>
              </div>
            )
          })}
        </div>
      </Fade>
    )
  }

  const crumbs = [
    { label: "Advice", href: "/advice" },
    {
      label: "Video Library",
      href: "/advice/video-library",
      active: true,
    },
  ]

  const [showModal, setShowModal] = useState(false)

  // We're using just one modal for the whole thing
  const [videoData, setVideoData] = useState({
    url: "",
  })

  // Make the modal switch data depending on what you've clicked
  function handleVideoClick(url, youtube_link) {
    setVideoData({
      url: url,
      youtube_link: youtube_link,
    })
    setShowModal(true)
  }

  return (
    <article>
      <Seo title={"Video Library"} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <section className={`bg-offwhite`}>
        <div className="container text-center pb-12">
          <Fade>
            <h1 className="font-display text-gold rfs:text-5xl">
              Video library
            </h1>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-2xl mt-6 m-auto">
              While our doors are of the finest quality and design, you don’t
              need to take our word for it. We have a wide range of stunning
              videos, showcasing the eloquence and functionality of our bespoke
              products. Discover our highly refined processes, innovative
              technical features, and see our incredible doors in action. If
              you’re looking for something else,{" "}
              <Link to="/contact/">let us know</Link> and we’ll do our best to
              provide additional videos for you.
            </p>
          </Fade>
        </div>
      </section>
      <section className="bg-offwhite overflow-hidden">
        <div className="container pt-12">
          <div>
            <div>
              <VideosListing data={props.data.allPrismicVideo.edges} />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black">
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              Got a project in mind?
            </h2>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-md mt-6 m-auto text-white">
              If you’ve got plans or are ready to start on a self-build project,
              get in touch for inspiration and expert advice to see how we can
              help.
            </p>
            <Link to="/contact/" className="btn mt-6">
              Contact the team
            </Link>
          </Fade>
        </div>
      </section>
      {/* The modal */}
      <div
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-500 ease-in-out ${
          showModal ? "visible" : "invisible"
        }`}
      >
        <div
          className={`relative flex justify-center items-center lg:p-0 p-6 w-auto lg:h-auto lg:my-6 mx-auto z-60 md:p-8`}
        >
          <div
            className={`shadow-lg relative flex flex-col w-full lg:min-h-full bg-white outline-none focus:outline-none z-50 transition-opacity duration-500 ease-in-out ${
              showModal ? "opacity-100" : "opacity-0"
            } `}
          >
            <div className="relative">
              <button
                className="absolute z-40 cursor-pointer -top-3 h-8 w-8 rounded-full -right-3 outline-none focus:outline-none bg-white hover:text-gold"
                onClick={() => setShowModal(false)}
              >
                <i className="fal fa-times"></i>
              </button>
              {showModal ? (
                // <video
                //   className="object-cover w-full h-full outline-none focus:outline-none"
                //   autoPlay
                //   controls
                //   muted
                // >
                //   <source src={videoData.url} type="video/mp4" />
                // </video>
                <div className="video-popup-container">
                  <RichText
                    render={videoData.youtube_link}
                    serializeHyperlink={GatsbyLink}
                  />
                  {/* <div data-oembed="https://www.youtube.com/watch?v=y9j-BL5ocW8&amp;ab_channel=BobykMedia" data-oembed-type="video" data-oembed-provider="YouTube" className="embed embed-youtube">
                    <div>
                      <iframe width="200" height="113" src="https://www.youtube.com/embed/y9j-BL5ocW8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                    </div>
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          className={`fixed inset-0 z-40 bg-darkgrey transition-opacity outline-none focus:outline-none duration-500 ease-in-out ${
            showModal ? "opacity-75" : "opacity-0"
          }`}
          role="button"
          tabIndex={0}
          onClick={() => setShowModal(false)}
          onKeyDown={() => setShowModal(false)}
        >
          <span className="invisible">Close modal</span>
        </div>
      </div>
      <ScrollLock isActive={showModal} accountForScrollbars={true} />
    </article>
  )
}

export default ArchiveMovieLibrary

export const ArchiveMovieLibraryQuery = graphql`
  query ArchiveMovieLibraryQuery {
    allPrismicBlogPost(
      filter: { tags: { eq: "Self Build" } }
      sort: { fields: data___post_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          tags
          data {
            title {
              text
            }
            featured_image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            description {
              richText
            }
            post_date(formatString: "DD MMM YYYY")
          }
        }
      }
    }
    allPrismicBrochure(filter: { tags: { eq: "Self Build" } }) {
      edges {
        node {
          data {
            title {
              text
            }
            featured_image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            download_file {
              url
            }
            description {
              richText
            }
          }
          tags
        }
      }
    }
    allPrismicDownloads {
      edges {
        node {
          data {
            title {
              text
            }
            description {
              richText
            }
            document {
              url
            }
            featured_image {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    allPrismicVideo {
      edges {
        node {
          data {
            title {
              text
            }
            description {
              richText
            }
            featured_image {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            link_to_video {
              text
            }
            youtube_link {
              richText
              text
            }
          }
        }
      }
    }
  }
`
